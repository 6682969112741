.mobilePreviewCard {
  margin: 0 auto;
  /* width: 260px; */
  width:auto;
  font-family: "Helvetica";
  color: black;
  font-size: 0.8rem;
  height: 430px;
}

.mobilePreviewCard.card {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.cardImageOverlayCustomiztion {
  margin: 68px 18px 63px 18px;
  width: 80%;
  /* height: 90%; */
  /* overflow-x: hidden; */
  /* overflow-y: hidden; */
  /* overflow-y: auto; */
}

.card-img-overlay {
  padding: 0px;
}

.cardImageOverlayCustomiztion .container {
  width: 100%;
  height: 100%;
  padding: 5px 5px;
  background-color: white;
}

.container .row {
  margin: 0px;
}

.container .col {
  padding: 0px;
}

.container .col-5 {
  padding: 0px;
}

.cardImageOverlayCustomiztion .header {
  text-align: left;
  /* width: 215px; */
  width: auto;
}

.header img {
  width: 32px;
}

.header .title {
  /* font-size: 10px; */
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 10px;
}

.header .subTitle {
  color: #aaa;
}

.previewAdPersonDot {
  background-color: #1a1a1a;
  width: 3px;
  height: 3px;
  margin: 5px 0 5px auto;
  border-radius: 50%;
  box-shadow: -6px 0px 0px #1a1a1a, 6px 0px 0px #1a1a1a;
}

.profileImageShow {
  position: absolute;
  width: 60px;
  right: 0;
  bottom: 0;
  padding: 3px;
  background: transparent;
}

.previewAdPersonDot-inst {
  background-color: #1a1a1a;
  width: 3px;
  height: 3px;
  margin: 5px 0 5px auto;
  border-radius: 100%;
  box-shadow: 0px -5px 0px 0px #1a1a1a, 0px 5px 0px 0px #1a1a1a;
}

.stuffDiv {
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.footer {
  /* width: 224px; */
  width : auto;
  margin-left: -5px !important;
  margin-right: -5px !important;
  padding: 5px 5px 0px 5px;
  background-color: #eee;
}

.footer-new {
  width: 224px;
  margin-left: -5px !important;
  margin-right: -5px !important;
  padding: 0 0 10px 0;
}

.adPreviewCustomButton.btn {
  width: 70%;
  margin-top: 10px;
  height: auto;
  padding: 4px;
  /* font-size: 8px; */
  font-size: 0.8rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #1a1a1a;
}

.previewAdLikeComments {
  width: 215px;
  margin-top: 2%;
}

.previewAdLikeComments-inst {
  width: 215px;
}

.previewAdDetailImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
  background-color: #f1f1f1;
}
