.App {
  text-align: center;
}

.center {
  text-align: center;
  vertical-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.red-alert-hyphen  {
  color: red;
  font-size: 200%;
}

.red-alert-small  {
  color: red;
}

.label {
  color:  black;
  margin-bottom: 0px;
  font-weight: bold;
  font-style: italic;
}

.form-group {
  margin-bottom: 20px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.btn-space-right {
  margin-right: 5px;
}

.btn-space-left {
  margin-left: 5px;
}


.crosssighns {
  z-index: 1;
  font-size: 1.1em;
  margin-left: 5px;
  margin-top: 3px;
  cursor: pointer;
  float: right;
}


.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Tabs {
  width: 100%;
  height: auto;
  min-height: 400px;
  background: #f3f3f3;
  /* margin: 3.5rem auto 1.5rem; */
  padding: 2rem 1rem;
  /* color: #E8F0F2; */
  /* border-radius: 2rem; */
  @media (max-width: 769px) {
   padding: 2rem 0;
  }
 }

 ul.nav {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f9ab93;
  border-radius: 2rem;
  @media (max-width: 768px) {
    width: 90%;
  }
}
ul.nav li {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}
ul.nav li:nth-child(2) {
  border-radius: 0;
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}
ul.nav li:hover {
  background: rgba(255, 186, 186, 0.164);
}
ul.nav li.active {
  background: #f9ab93;
}